import storage from './customstorage'
import routes from '../router/index'
import store from '../store/index'
import  websocket from './webSocket'
var tokenName = 'token'

// var refreshTime = null //刷新Token定时器
// var refresh_token = null //刷新令牌
// var expires_in = null //Token过期时间

// //登录
// export function postLogin(username, password) {
// 		//如果没有用户信息 则登录
// 		if(!storage.getItem('userInfo').id)
// 		{
// 		  	var result=userInfoDomain.Login(username, password)
// 				if(result)
// 				{
// 					if(tokenClient.Token())
// 				{
// 						storage.setItem({
// 							name: tokenName,
// 							value: tokenClient.Token().AccessToken,
// 							expires: tokenClient.Token().ExpiresIn  * 1000,
// 						}) //将token存储起来
// 						analysisToken(tokenClient.Token().AccessToken)
// 						result=true;
// 				}
// 				}
// 				return result
// 		}
// }

// //自动刷新Token
// function refreshToken() {
// 	window.clearTimeout(refreshTime)
// 	refreshTime = setInterval(() => {
// 		var postData = qs.stringify({
// 			refresh_token: refresh_token,
// 			grant_type: 'refresh_token',
// 			client_id: client_id,
// 			client_secret: client_secret,
// 		})
// 		http.post(`${login}/connect/token`, postData)
// 			.then((res) => {
// 				if (res.status === 200) {
// 					storage.setItem({
// 						name: tokenName,
// 						value: res.data.access_token,
// 						expires: res.data.expires_in * 1000,
// 					}) //将token存储起来
// 					expires_in = res.data.expires_in
// 					refresh_token = res.data.refresh_token
// 					analysisToken(res.data.access_token)
// 				}
// 			})
// 			.catch((err) => {
// 				console.log(err)
// 			})
// 	}, 1000 * expires_in)
// }

//如果用户信息，如果为空返回false
export function getUserInfo() {
	var userinfo = store.state.localData.userInfo
	if ($.isEmptyObject(userinfo)) return false
	return userinfo
}

//刷新用户信息到缓存
export function userInfoLoad(
	name,
	phone,
	titleId,
	postId,
	telephone,
	address,
	abstract,
	photo
) {
	var userinfo = getUserInfo()
	if (userinfo != false) {
		 //将token存储起来
		store.commit("localData/userInfo", {
			id: parseInt(userinfo.id), //用户ID
			name: name, //用户姓名
			phone: phone, //电话号
			telephone: telephone,
			address: address,
			abstract: abstract,
			categoryKey: userinfo?.categoryKey,
			category: userinfo?.category,
			sex: userinfo.sex, //性别
			photo: photo ?? userinfo.photo, //头像
			organizationId: parseInt(userinfo.organizationId ?? 0), //所属单位ID
			organizationName: userinfo.organizationName, //所属单位名称
			departmentId: parseInt(userinfo.departmentId ?? 0), //所属科室ID
			department: userinfo.department, //所属科室名称
			title: userinfo.title, //职称
			titleId: parseInt(titleId ?? 0), //职称ID
			post: userinfo.post, //职务
			postId: parseInt(postId ?? 0), //职务ID
			roleId: parseInt(userinfo.roleId ?? 0),
			isgreenChannel: userinfo.isgreenChannel,
			isAuditDiagnose: userinfo.IsAuditDiagnose,
			isMakeDiagnose: userinfo.IsMakeDiagnose,
			detailUserType:userinfo.detailUserType.toString()
		})
	}
}

//退出登录
export function loginOut() {
	if (websocket.webClosed.length != 0) {
		websocket.webClosed()
	}
	storage.removeItem('messageStore')
	storage.removeItem('token')
	window.sessionStorage.clear()
	localStorage.clear()
	routes.replace({ path: '/login' })
	// location.reload()
}
