import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/login',
		meta: {
			title: '千越医疗',
		},
	},
	{
		path: '/index',
		name: 'index',
		meta: {
			title: '千越医疗',
		},
		component: () => import('../views/index.vue'),
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '登录',
		},
		component: () => import('../views/Login.vue'),
	},
	{
		path: '/searchList' /*首页搜索列表*/,
		name: 'searchList',
		meta: {
			keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/searchList.vue'),
	},
	{
		path: '/messageIndex' /*首页搜索列表*/,
		name: 'messageIndex',
		meta: {
			keepAlive: false    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/messageIndex.vue'),
	},
	{
		path: '/messageDetail' /*首页搜索列表*/,
		name: 'messageDetail',
		meta: {
			keepAlive: false    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/messageDetail.vue'),
	},
	{
		path: '/disease/diseaseBank' /*疾病列表*/,
		name: 'diseaseBank',
		meta: {
			keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/disease/DiseaseBank.vue'),
	},
	{
		path: '/disease/diseaseDetail' /*疾病详情*/,
		name: 'diseaseDetail',
		component: () => import('../views/disease/DiseaseDetail.vue'),
	},
	{
		path: '/Drug/drugBank' /*药品列表*/,
		name: 'drugBank',
		meta: {
			keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/Drug/DrugBank.vue'),
	},
	
	{
		path: '/Drug/Beauty' /*养生美容*/,
		name: 'Beauty',
		component: () => import('../views/Drug/Beauty.vue'),
	},
	{
		path: '/Drug/drugDetail' /*药品详情*/,
		name: 'drugDetail',
		component: () => import('../views/Drug/DrugDetail.vue'),
	},
	{
		path: '/disease/MeridianBank' /*经络列表*/,
		name: 'MeridianBank',
		meta: {
			keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/disease/MeridianBank.vue'),
	},
	{
		path: '/disease/MeridianDetail' /*经络详情*/,
		name: 'MeridianDetail',
		component: () => import('../views/disease/MeridianDetail.vue'),
	},
	{
		path: '/Drug/Material' /*中药列表*/,
		name: 'material',
		meta: {
			keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/Drug/Material.vue'),
	},
	{
		path: '/Drug/MaterialDetail' /*中药详情*/,
		name: 'materialDetail',
		component: () => import('../views/Drug/MaterialDetail'),
	},
	{
		path: '/Drug/Food' /*食疗药膳列表*/,
		name: 'food',
		meta: {
			keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/Drug/Food.vue'),
	},
	{
		path: '/Drug/FoodDetail' /*食疗药膳详情*/,
		name: 'foodDetail',
		component: () => import('../views/Drug/FoodDetail.vue'),
	},
	{
		path: '/Drug/Herbal' /*中药方剂列表*/,
		name: 'herbal',
		meta: {
			keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/Drug/Herbal.vue'),
	},
	{
		path: '/Drug/HerbalDetail' /*中药方剂详情*/,
		name: 'herbalDetail',
		component: () => import('../views/Drug/HerbalDetail.vue'),
	},
	{
		path: '/Drug/HealthyDiet' /*健康饮食列表*/,
		name: 'HealthyDiet',
		meta: {
			keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
		},
		component: () => import('../views/Drug/HealthyDiet.vue'),
	},
	{
		path: '/Drug/HealthyDetail' /*健康饮食详情*/,
		name: 'HealthyDetail',
		component: () => import('../views/Drug/HealthyDetail.vue'),
	},
	{
		path: '/Compatibility' /*配伍*/,
		name: 'compatibility',
		component: () => import('../views/Compatibility.vue'),
	},
	{
		path: '/CompaDetail' /*配伍详情*/,
		name: 'compaDetail',
		component: () => import('../views/CompaDetail.vue'),
	},
	{
		path: '/diagnose/tcmDiagnosis' /*中医诊断*/,
		name: 'tcmDiagnosis',
		component: () => import('../views/diagnose/tcmDiagnosis.vue'),
	},
	{
		path: '/diagnose/tcmDetail' /*中医诊断*/,
		name: 'tcmDetail',
		component: () => import('../views/diagnose/tcmDetail.vue'),
	},
	{
		path: '/diagnose/Diagnose' /*症状诊断*/,
		name: 'diagnose',
		component: () => import('../views/diagnose/Diagnose.vue'),
	},
	{
		path: '/diagnose/DiagDetail' /*症状诊断详情*/,
		name: 'diagDetail',
		component: () => import('../views/diagnose/DiagDetail.vue'),
	},
	{
		path: '/diagnose/Examine' /*检查诊断*/,
		name: 'Examine',
		component: () => import('../views/diagnose/Examine.vue'),
	},
	{
		path: '/diagnose/ExamDetail' /*检查诊断详情*/,
		name: 'Examine',
		component: () => import('../views/diagnose/ExamDetail.vue'),
	},
	{
		path: '/diagnose/Inspection' /*检验检测*/,
		name: 'Inspection',
		component: () => import('../views/diagnose/Inspection.vue'),
	},
	{
		path: '/diagnose/signsCheck' /*体征检查*/,
		name: 'signsCheck',
		component: () => import('../views/diagnose/signsCheck.vue'),
	},
	{
		path: '/legislation/Legislation' /*法律法规列表*/,
		name: 'legislation',
		component: () => import('../views/legislation/Legislation.vue'),
	},
	{
		path: '/imgShow' ,
		name: 'imgShow',
		component: () => import('../views/imgShow.vue'),
	},
	{
		path: '/legislation/LegislaDetail' /*法律法规详情*/,
		name: 'legislaDetail',
		component: () => import('../views/legislation/LegislaDetail.vue'),
	},
	{
		path: '/personal/index' /*个人中心首页*/,
		name: 'personalIndex',
		component: () => import('../views/personal/index.vue'),
	},
	{
		path: '/personal/adressList' /*个人中心地址*/,
		name: 'adressList',
		component: () => import('../views/personal/adressList.vue'),
	},
	{
		path: '/personal/personalMoney' /*个人中心余额*/,
		name: 'personalMoney',
		component: () => import('../views/personal/personalMoney.vue'),
	},
	{
		path: '/personal/referral' /*个人中心-转诊*/,
		name: 'personalReferral',
		component: () => import('../views/personal/Referral.vue'),
	},
	{
		path: '/personal/treated' /*个人中心-接诊*/,
		name: 'personalTreated',
		component: () => import('../views/personal/Treated.vue'),
	},
	{
		path: '/personal/greenChannel' /*个人中心-绿色通道*/,
		name: 'personalGreenChannel',
		component: () => import('../views/personal/GreenChannel.vue'),
	},
	{
		path: '/personal/Message' /*个人中心-消息通知*/,
		name: 'Message',
		component: () => import('../views/personal/Message.vue'),
	},
	{
		path: '/personal/Collect' /*个人中心-收藏*/,
		name: 'Collect',
		component: () => import('../views/personal/Collect.vue'),
	},
	{
		path: '/personal/SelfTest' /*个人中心-自测记录*/,
		name: 'SelfTest',
		component: () => import('../views/personal/SelfTest.vue'),
	},
	{
		path: '/personal/Browsing' /*个人中心-浏览记录*/,
		name: 'Browsing',
		component: () => import('../views/personal/Browsing.vue'),
	},
	{
		path: '/personal/resetPassword' /*个人中心-修改密码*/,
		name: 'resetPassword',
		component: () => import('../views/personal/resetPassword.vue'),
	},
	{
		path: '/company/company' /*公司简介*/,
		name: 'company',
		component: () => import('../views/company/company.vue'),
	},
	{
		path: '/company/feedback' /*意见反馈*/,
		name: 'feedback',
		component: () => import('../views/company/feedback.vue'),
	},
	{
		path: '/company/disclaimer' /*免责声明*/,
		name: 'disclaimer',
		component: () => import('../views/company/disclaimer.vue'),
	},
	{
		path: '/company/UserAgreement' /*服务协议*/,
		name: 'UserAgreement',
		component: () => import('../views/company/UserAgreement.vue'),
	},
	{
		path: '/company/news' /*新闻列表*/,
		name: 'news',
		component: () => import('../views/company/news.vue'),
	},
	{
		path: '/company/newsDetail' /*新闻详情*/,
		name: 'newsDetail',
		component: () => import('../views/company/newsDetail.vue'),
	},
	{
		path: '/company/companyNews' /*公司动态*/,
		name: 'companyNews',
		component: () => import('../views/company/companyNews.vue'),
	},
	{
		path: '/company/selfTest' /*健康自测*/,
		name: 'selfTest',
		component: () => import('../views/company/selfTest.vue'),
	},
	{
		path: '/company/startTest' /*健康自测*/,
		name: 'startTest',
		component: () => import('../views/company/startTest.vue'),
	},
	{
		path: '/company/testResult' /*自测结果*/,
		name: 'testResult',
		component: () => import('../views/company/testResult.vue'),
	},
	{
		path: '/company/resultInfo' /*自测结果详情*/,
		name: 'resultInfo',
		component: () => import('../views/company/resultInfo.vue'),
	},
	{
		path: '/company/usehelp' /*使用帮助*/,
		name: 'usehelp',
		component: () => import('../views/company/usehelp.vue'),
	},
	{
		path: '/company/productPrice' /*产品报价*/,
		name: 'productPrice',
		component: () => import('../views/company/productPrice.vue'),
	},
	{
		path: '/company/contactUs' /*联系我们*/,
		name: 'contactUs',
		component: () => import('../views/company/contactUs.vue'),
	},
	{
		path: '/healthKnow/healthKnow' /*健康常识*/,
		name: 'healthKnow',
		component: () => import('../views/healthKnow/healthKnow.vue'),
	},
	{
		path: '/familyFirstAid/familyFirstAid' /*家庭急救*/,
		name: 'familyFirstAid',
		component: () => import('../views/familyFirstAid/familyFirstAid.vue'),
	},
	{
		path: '/healthKnow/ClinicalPractice' /*临床技术操作规范*/,
		name: 'ClinicalPractice',
		component: () => import('../views/healthKnow/ClinicalPractice.vue'),
	},
	{
		path: '/thirdPartyLogin' /*His用户登录*/,
		name: 'thirdPartyLogin',
		component: () => import('../views/ThirdPartyLogin.vue'),
	},
	{
		path: '/video/videoPage' /*视频首页*/,
		name: 'videoPage',
		component: () => import('../views/video/videoPage.vue'),
	},
	{
		path: '/video/videoDetail' /*视频详情*/,
		name: 'videoDetail',
		component: () => import('../views/video/videoDetail.vue'),
	},
	{
		path: '/video/addVideo' /*添加视频*/,
		name: 'addVideo',
		component: () => import('../views/video/addVideo.vue'),
	},
	{
		path: '/patienTmanagement/patientList' /*患者列表*/,
		name: 'patientList',
		component: () => import('../views/patienTmanagement/patientList.vue'),
	},
	{
		path: '/patienTmanagement/recordList' /*复诊记录*/,
		name: 'recordList',
		component: () => import('../views/patienTmanagement/recordList.vue'),
	},
	{
		path: '/patienTmanagement/addPatient' /*添加患者*/,
		name: 'addPatient',
		component: () => import('../views/patienTmanagement/addPatient.vue'),
	},
	{
		path: '/patienTmanagement/addRecord' /*添加复诊*/,
		name: 'addRecord',
		component: () => import('../views/patienTmanagement/addRecord.vue'),
	},
	{
		path: '/patienTmanagement/prescriptionList' /*处方列表*/,
		name: 'prescriptionList',
		component: () => import('../views/patienTmanagement/addprescript.vue'),
	},
	{
		path: '/patienTmanagement/addprescript' /*开具处方*/,
		name: 'addprescript',
		component: () => import('../views/patienTmanagement/addprescript.vue'),
	},
	{
		path: '/patienTmanagement/prescriptList' /*处方列表*/,
		name: 'prescriptList',
		component: () => import('../views/patienTmanagement/prescriptList.vue'),
	},
	{
		path: '/patienTmanagement/stateprescriptList' /*代取药列表*/,
		name: 'stateprescriptList',
		component: () => import('../views/patienTmanagement/stateprescriptList.vue'),
	},
	{
		path: '/patienTmanagement/successprescriptList' /*已完成列表*/,
		name: 'successprescriptList',
		component: () => import('../views/patienTmanagement/successprescriptList.vue'),
	},
	{
		path: '/video/videoList' /*视频列表*/,
		name: 'videoList',
		component: () => import('../views/video/videoList.vue'),
	},
	{
		path: '/video/collectList' /*合集列表*/,
		name: 'collectList',
		component: () => import('../views/video/collectList.vue'),
	},
	{
		path: '/video/collectVideoList' /*合集视频列表*/,
		name: 'collectVideoList',
		component: () => import('../views/video/collectVideoList.vue'),
	},
	{
		path: '/video/addcollect' /*添加合集视频*/,
		name: 'addcollect',
		component: () => import('../views/video/addcollect.vue'),
	},
	{
		path: '/patienTmanagement/prescriptdetail' /*处方详情*/,
		name: 'prescriptdetail',
		component: () => import('../views/patienTmanagement/prescriptdetail.vue'),
	},
	{
		path: '/video/transactionRecords' /*处方详情*/,
		name: 'transactionRecords',
		component: () => import('../views/video/transactionRecords.vue'),
	},
	// 中医问诊
	{
		path: '/zdrugconsultation/zDrugPage' /*中医问诊首页*/,
		name: 'zDrugPage',
		component: () => import('../views/zdrugconsultation/zDrugPage.vue'),
	},
	{
		path: '/zdrugconsultation/zDrugStart' /*中医问诊*/,
		name: 'zDrugStart',
		component: () => import('../views/zdrugconsultation/zDrugStart.vue'),
	},
	{
		path: '/zdrugconsultation/zdrugDetail' /*问诊详情*/,
		name: 'zdrugDetail',
		component: () => import('../views/zdrugconsultation/zdrugDetail.vue'),
	},
	{
		path: '/zdrugconsultation/zDrugSearch' /*问诊详情*/,
		name: 'zDrugSearch',
		component: () => import('../views/zdrugconsultation/zDrugSearch.vue'),
	},
	{
		path: '/zdrugconsultation/zDrugProgress' /*问诊详情*/,
		name: 'zDrugProgress',
		component: () => import('../views/zdrugconsultation/zDrugProgress.vue'),
	},
	{
		path: '/zdrugconsultation/zdrugsynDetail' /*问诊详情*/,
		name: 'zdrugsynDetail',
		component: () => import('../views/zdrugconsultation/zdrugsynDetail.vue'),
	},
	// 辨病论治
	{
		path: '/diseaseConsultation/diseasesynDetail' /*辨病论治*/,
		name: 'diseasesynDetail',
		component: () => import('../views/diseaseConsultation/diseasesynDetail.vue'),
	},
	{
		path: '/diseaseConsultation/diseaseDetail' /*辨病论治*/,
		name: 'diseaseDetail',
		component: () => import('../views/diseaseConsultation/diseaseDetail.vue'),
	},
	{
		path: '/diseaseConsultation/diseasePage' /*辨病论治*/,
		name: 'diseasePage',
		component: () => import('../views/diseaseConsultation/diseasePage.vue'),
	},
	{
		path: '/diseaseConsultation/diseaseProgress' /*辨病论治*/,
		name: 'diseaseProgress',
		component: () => import('../views/diseaseConsultation/diseaseProgress.vue'),
	},
	{
		path: '/diseaseConsultation/diseaseSearch' /*辨病论治*/,
		name: 'diseaseSearch',
		component: () => import('../views/diseaseConsultation/diseaseSearch.vue'),
	},
	{
		path: '/diseaseConsultation/diseaseStart' /*辨病论治*/,
		name: 'diseaseStart',
		component: () => import('../views/diseaseConsultation/diseaseStart.vue'),
	},
	{
		path: '/dictionaryLIst/dictionaryIndex' /*辞典*/,
		name: 'dictionaryIndex',
		component: () => import('../views/dictionaryLIst/dictionaryIndex.vue'),
	},
	{
		path: '/dictionaryLIst/searchDic' /*辞典*/,
		name: 'searchDic',
		component: () => import('../views/dictionaryLIst/searchDic.vue')
	},
	{
		path: '/dictionaryLIst/dictionaryDetail' /*辞典*/,
		name: 'dictionaryDetail',
		component: () => import('../views/dictionaryLIst/dictionaryDetail.vue')
	},
	{
		path: '/interrogation/interrogationIndex' /*辞典*/,
		name: 'interrogationIndex',
		component: () => import('../views/interrogation/interrogationIndex.vue')
	},
	{
		path: '/interrogation/intPatientList' /*辞典*/,
		name: 'intPatientList',
		component: () => import('../views/interrogation/intPatientList.vue')
	},
	{
		path: '/interrogation/intPatientListDtail' /*辞典*/,
		name: 'intPatientListDtail',
		component: () => import('../views/interrogation/intPatientListDtail.vue')
	},
	{
		path: '/interrogation/PatientListseeDtail' /*辞典*/,
		name: 'PatientListseeDtail',
		component: () => import('../views/interrogation/PatientListseeDtail.vue')
	},
	{
		path: '/interrogation/preintPatientList' /*辞典*/,
		name: 'preintPatientList',
		component: () => import('../views/interrogation/preintPatientList.vue')
	},
	{
		path: '/interrogation/PremangePatientList' /*辞典*/,
		name: 'PremangePatientList',
		component: () => import('../views/interrogation/PremangePatientList.vue')
	},
	{
		path: '/interrogation/orderList' /*辞典*/,
		name: 'orderList',
		component: () => import('../views/interrogation/orderList.vue')
	},
	{
		path: '/interrogation/OrderInfo' /*辞典*/,
		name: 'OrderInfo',
		component: () => import('../views/interrogation/OrderInfo.vue')
	},
	{
		path: '/interrogation/ChargedOrRefund' /*辞典*/,
		name: 'ChargedOrRefund',
		component: () => import('../views/interrogation/ChargedOrRefund.vue')
	},
	{
		path: '/interrogation/OrRefunDetail' /*辞典*/,
		name: 'OrRefunDetail',
		component: () => import('../views/interrogation/OrRefunDetail.vue')
	},
	{
		path: '/interrogation/orderDetail' /*辞典*/,
		name: 'orderDetail',
		component: () => import('../views/interrogation/orderDetail.vue')
	},
	{
		path: '/interrogation/PremangeseeDtail' /*辞典*/,
		name: 'PremangeseeDtail',
		component: () => import('../views/interrogation/PremangeseeDtail.vue')
	},
	{
		path: '/drugInterrogation/drugPremangeseeDtail' /*辞典*/,
		name: 'drugPremangeseeDtail',
		component: () => import('../views/drugInterrogation/drugPremangeseeDtail.vue')
	},
	{
		path: '/drugInterrogation/drugPremangePatientList' /*辞典*/,
		name: 'drugPremangePatientList',
		component: () => import('../views/drugInterrogation/drugPremangePatientList.vue')
	},
	{
		path: '/shop/shopPage' /*辞典*/,
		name: 'shopPage',
		component: () => import('../views/shop/shopPage.vue')
	},
	{
		path: '/interrogation/referralAndmedical' /*接诊管理*/,
		name: 'referralAndmedical',
		component: () => import('../views/interrogation/referralAndmedical.vue')
	},
	{
		path: '/interrogation/referralDtail' /*接诊详情*/,
		name: 'referralDtail',
		component: () => import('../views/interrogation/referralDtail.vue')
	}
]

const router = new VueRouter({
	routes,
	scrollBehavior(to, from, saveTop) {
		if (saveTop) {
			return saveTop
		} else {
			return { x: 0, y: 0 }
		}
	},
})

export default router
