import { ApiClient } from '@/components/tokenclient/ApiClient.js';
let DrugExtend = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取同类药 (药品)
     * @param {any} params
     * @param {any} success
     * @param {any} error
     */
    this.Relations = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/DrugExtend/Relations/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取药品、成分、食疗药膳、中药、中药方剂名称
     * @param {any} success
     * @param {any} error
     */
    this.DrugsName = function (success, error) {
        var url = ServiceBaseAddress + '/api/DrugPublic/GetOtherNames';
        ApiClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
        // TokenClient.Get(url, true, null, null,
        //     function (data) {
        //         if (success) {
        //             var jsonObj = JSON.parse(data);
        //             success(jsonObj);
        //         }
        //     }, error);
    }
    /**
  * 获取同类药 ()
  * @param {any} params
  * @param {any} success
  * @param {any} error
  */
    this.Relation = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/DrugExtend/Relation/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { DrugExtend }