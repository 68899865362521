<template>
  <div class="navheader">
    <div class="topbar">
      <div class="topbar-box clearfix">
        <div class="topbar-l">
          <!-- <p><img src="../assets/img/tel_icon.png" alt="">咨询热线:<span>18639512888</span>or E-mail: <i>3248344350@qq.com</i></p>-->
        </div>
        <div class="personalCenter">
          <div class="login-box" v-if="!userId">
            <p class="login-txt">
              <router-link to="/login"><i class="el-icon-edit"></i>登录</router-link>
            </p>
          </div>
          <div v-else>
            <div style='display:flex; align-items: center;'>
                <div class="mesTopBox"
                  style="display:flex;align-items: center;cursor: pointer;position: relative;margin-right: 5px;position: relative;"
                  @click="gomessIndex">
                  <i class="el-icon-bell" style="font-size:20px"></i>
                  <p style="margin-left:10px">消息通知</p>
                  <div class="mesTopItem" v-if="IsHomeMessage">
                  </div>
                </div>
                          <div class="login-box" >
            <p class="login-txt">
              <router-link to="/personal/index">
                <i class="mini-head">
                  <img v-if="userhead == '' || userhead == null" src="../assets/img/head_img.png" alt="" />
                  <img v-else :src="Services.Authorization + userhead" alt="" />
                </i>
                {{ userName }}<span v-if="roleId == 3">医生</span>
              </router-link>
            </p>
            <div class="per-hover">
              <div class="head-box">
                <div class="head-img">
                  <img v-if="userhead == '' || userhead == null" src="../assets/img/head_img.png" alt="" />
                  <img v-else :src="Services.Authorization + userhead" alt="" />
                </div>
                <p>{{ userName }}<span v-if="roleId == 3">医生</span></p>
              </div>
              <ul class="per-info clearfix">
                <li>
                  <router-link to="/personal/personalMoney">
                    <div class="s-icon s-icon4"></div>
                    <p>账单管理<span class="layui-badge-dot"></span></p>
                  </router-link>
                </li>
                <li>
                  <router-link to="/interrogation/referralAndmedical">
                    <div class="s-icon s-icon3"></div>
                    <p>转诊管理<span class="layui-badge-dot"></span></p>
                  </router-link>
                </li>
                <li>
                  <router-link to="/personal/SelfTest">
                    <div class="s-icon s-icon2"></div>
                    <p>测试记录</p>
                  </router-link>
                </li>
              </ul>
              <div class="status">
                <span><router-link to="/personal/index" style="color: #1fb7a6">个人中心</router-link></span>
                <span><a href="javascript:void(0);" @click="out">退出登录</a></span>
              </div>
            </div>
          </div>
            </div>
          </div>
          <div v-if="$ServicesData1.ClientId != 'MedicineDiagnosis.Web'">
            <div class="feedback test" v-if="userType == '1' && personalData.isPharmacist != 1">
              <div class="test" @click="goInterrogationIndex1" >
                <i class="el-icon-notebook-2" style="font-size: 15px; margin-bottom: -5px; font-weight: 400;margin-right: 5px;"></i>
                <span>门诊</span>
              </div>
            </div>
          <div class="feedback test" v-if="personalData.isPharmacist == 1">
            <div class="test" @click="goInterrogationIndex1" >
                <i class="el-icon-notebook-2" style="font-size: 15px; margin-bottom: -5px; font-weight: 400;margin-right: 5px;"></i>
                <span>审核处方</span>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
    <div class="nav-wrap">
      <nav class="clearfix">
        <div class="navbar">
          <ul>
            <li v-for="(item, index) in menuList" :key="index">
              <template v-if="item.url == '/'">
                <template v-if="item.name == '诊断' || item.name == '药品库'">
                  <a href="javascript:void(0);">{{ item.name }}<i class="el-icon-caret-bottom"></i></a>
                </template>
                <template v-else>
                  <a href="javascript:void(0);">{{ item.name }}</a>
                </template>
                <dl class="sub-nav">
                  <dt v-for="(subItem, i) in item.children" :key="i">
                    <router-link v-if="subItem.url != undefined" :to="subItem.url">{{ subItem.name }}</router-link>
                  </dt>
                </dl>
              </template>
              <template v-else>
                <router-link v-if="item.url != undefined" :to="item.url">{{ item.name }}</router-link>
              </template>
            </li>
            <!-- <li @click="goInterrogationIndex">辨病论治</li> -->
            <!-- <li @click="goInterShopIndex">商城</li> -->
          </ul>
        </div>
        <div class="logo">
            <img v-if="orgimgkeyPathData2" :src=" orgimgkeyPathData2.orgimgkeyPath2" alt="" />
            <img v-else-if="getSchoolName" :src="getSchoolName" alt="" />
            <img v-else src="../assets/img/logo.png" alt="" />
        </div>
      </nav>
    </div>
    <!-- <touch></touch> -->
    <!-- 资质认证 -->
    <el-dialog
      title="资质认证"
      :visible.sync="certificateAuditVisible"
      width="40%">
      <div class="AuditVisibleBox">
        <div class="AuditVisibleDes" v-if="GetCertificateState == 2">拒绝原因：<span style="color:#FF0000">{{GetCertificateState.remark}}</span></div>
        <div class="AuditVisibleTitle" style="margin-top: 0px;">身份证人像面<span style="color:#FF0000">*</span></div>
        <div class="AuditVisibleDes">1.请上传<span style="color:#FF0000">身份证人像面</span>完整、清晰的照片;</div>
        <el-upload
          :action="FileUploadUrl"
          :headers="headers"
          :limit='1'
          list-type="picture-card"
          :on-preview="handlePictureCardPreview1"
          :on-success="handleAvatarSuccess1"
         >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible1">
            <img width="100%" :src="dialogImageUrl1" alt="">
          </el-dialog>
      </div>
      <div class="AuditVisibleBox">
        <div class="AuditVisibleTitle">身份证国徽面<span style="color:#FF0000">*</span></div>
        <div class="AuditVisibleDes">1.请上传<span style="color:#FF0000">身份证国徽面</span>完整、清晰的照片;</div>
        <el-upload
          :action="FileUploadUrl"
          :headers="headers"
          :limit='1'
          list-type="picture-card"
          :on-preview="handlePictureCardPreview2"
          :on-success="handleAvatarSuccess2"
          >
          <i class="el-icon-plus"></i>
        </el-upload>
         <el-dialog :visible.sync="dialogVisible2">
            <img width="100%" :src="dialogImageUrl2" alt="">
          </el-dialog>
      </div>
      <div class="AuditVisibleBox">
        <div class="AuditVisibleTitle">资质认证<span style="color:#FF0000">*</span></div>
        <div class="AuditVisibleDes">1.请上传相关的<span style="color:#FF0000">执业证书国徽页、个人照片页、个人信息页
            </span>完整、清晰的照片，最多5张；
        </div>
        <el-upload
          :action="FileUploadUrl"
          :headers="headers"
          :limit='5'
          multiple
          list-type="picture-card"
          :on-preview="handlePictureCardPreview3"
          :on-success="handleAvatarSuccess3"
          >
          <i class="el-icon-plus"></i>
        </el-upload>
               <el-dialog :visible.sync="dialogVisible3">
            <img width="100%" :src="dialogImageUrl3" alt="">
          </el-dialog>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certificateAuditVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCertificateAuditVisible">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import touch from "../components/touch";
import storage from "../utils/customstorage";
import { loginOut } from "../utils/userInfo";
import { Menus } from "./Menu/Menu";
import { MessageDomain } from '../components/personal/Message';
import { patientMain } from '../components/patienTmanagement/patienTmanagement';
import { UserInfo } from "../components/User/UserInfo"
export default {
  name: "navheader",
  inject: ["reload"],
  components: {
    touch,
  },
  props: {
    getSchoolName:String
  },
  data() {
    var menu = new Menus(this.TokenClient, this.Services.Authorization);
    var MessageDomainTemp = new MessageDomain(this.TokenClient, this.Services.Authorization);
    var patientMainTemp = new patientMain(this.TokenClient, this.Services.Authorization);
    var user = new UserInfo(this.TokenClient, this.Services.Authorization)
    return {
      menuDomain: menu,
      patientMainDomain:patientMainTemp,
      userDomain: user,
      menuList: [],
      userType: this.$store.state.localData.userInfo.detailUserType,
      userId: this.$store.state.localData.userInfo.id,
      userName: this.$store.state.localData.userInfo.name,
      userhead:  this.$store.state.localData.userInfo.photo,
      roleId: this.$store.state.localData.userInfo.roleId,
      orgimgkeyPathData2:'',
      IsHomeMessage: this.$store.state.isRead,
      Result: MessageDomainTemp,
      certificateAuditVisible:false,
      FileUploadUrl: this.Services.Authorization + "/api/File/upload",
      asstoken:'',
      idCard1:'',
      idCard2:'',
      idCard3:[],
      dialogImageUrl1:'',
      dialogImageUrl2:'',
      dialogImageUrl3:'',
      dialogVisible3:false,
      dialogVisible2:false,
      dialogVisible1:false,
      GetCertificateState:0,
      personalData:{}
    };
  },
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + this.asstoken,
      };
    },
  },
  async created() {
    this.orgimgkeyPathData2 = JSON.parse(localStorage.getItem('orgimgData2')) ? JSON.parse(localStorage.getItem('orgimgData2')) : ''
    this.asstoken = await this.TokenClient.AccessToken();
    this.getMenus();
    this.getHomeMessageData()
    this.LoadUserInfo()
  },
  methods: {
    LoadUserInfo(){
        var _this = this;
        _this.userDomain.UserMes(function (data) {
          _this.personalData = data.data;
          sessionStorage.setItem('userInfo', JSON.stringify(data.data))
        },function (error) {
            
        })
    },
    handlePictureCardPreview1(file){
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
    },
    handlePictureCardPreview2(file){
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    },
    handlePictureCardPreview3(file){
      this.dialogImageUrl3 = file.url;
      this.dialogVisible3 = true;
    },
    handleAvatarSuccess1(res){
      this.idCard1 = res[0].completeFilePath
    },
    handleAvatarSuccess2(res){
      this.idCard2 = res[0].completeFilePath
    },
    handleAvatarSuccess3(res){
      this.idCard3.push(
        res[0].completeFilePath
      )
    },
    addCertificateAuditVisible(){
       let _this = this;
       if (this.idCard1 == '') {
          _this.$message({
            message: '请上传身份证人像面',
            type: 'error'
          });
          return
       }
      if (this.idCard2 == '') {
          _this.$message({
            message: '请上传身份证国徽面',
            type: 'error'
          });
          return
       }
      if (this.idCard3.length == 0) {
          _this.$message({
            message: '请上传资质认证',
            type: 'error'
          });
          return
       }
       let params = {
        idCardFront: _this.idCard1,
        idCardBack:_this.idCard2,
        certificateImage: _this.idCard3.toString()
       }
        _this.patientMainDomain.GetUploadUserCertificateAudit(params,function (data) {
            console.log(data.data)
            _this.$message({
              message: '提交审核成功',
              type: 'success'
            });
            _this.certificateAuditVisible = false
        }, function (error) {
            console.log(error);
        })
    },
    goUploadUserCertificateAudit(){
      let _this = this;
      if (_this.$ServicesData1.ClientId == "TotalWeb") {
          let url = _this.$router.resolve({
            name: "interrogationIndex",
          });
          window.open(url.href, "_blank");
          return
      }
      _this.idCard3 = []
      // -1:未上传  0待审核，1 审核通过，2 审核未通过
       _this.patientMainDomain.GetCertificate(function (data) {
        _this.GetCertificateState = data.data
        if (data.data.ifUpload == 1) {
            if (_this.personalData.isPharmacist == 1) {
              let url = _this.$router.resolve({
                name: "drugPremangePatientList",
              });
              window.open(url.href, "_blank");
            } else {
              let url = _this.$router.resolve({
                name: "interrogationIndex",
              });
              window.open(url.href, "_blank");
            }
        } else if (data.data.ifUpload == -1) {
             _this.$confirm('您好，您的账户未上传有效身份证件及资质证书，请于2025年1月1日前，在线上传所需资料，您也可以联系本机构管理员进行操作！过时将影响您部分功能的使用，请您知晓并理解！', '提示', {
                confirmButtonText: '立刻上传',
                cancelButtonText: '暂不上传,继续使用',
                type: 'warning'
              }).then(() => {
                  _this.certificateAuditVisible = true
              }).catch(() => {
                  if (_this.personalData.isPharmacist == 1) {
                    let url = _this.$router.resolve({
                      name: "drugPremangePatientList",
                    });
                    window.open(url.href, "_blank");
                  } else {
                    let url = _this.$router.resolve({
                      name: "interrogationIndex",
                    });
                    window.open(url.href, "_blank");
                  }
              });
        } else if (data.data.ifUpload == 0) {
                  _this.$message({
                    message: '正在审核中...',
                    type: 'warning'
                  });
                if (_this.personalData.isPharmacist == 1) {
                  let url = _this.$router.resolve({
                    name: "drugPremangePatientList",
                  });
                  window.open(url.href, "_blank");
                } else {
                  let url = _this.$router.resolve({
                    name: "interrogationIndex",
                  });
                  window.open(url.href, "_blank");
                }
        } else if (data.data.ifUpload == 2) {
            _this.$confirm('拒绝理由:' + data.data.remark, '提示', {
              confirmButtonText: '立刻上传',
              cancelButtonText: '暂不上传,继续使用',
              type: 'warning'
            }).then(() => {
                _this.certificateAuditVisible = true
            }).catch(() => {
                if (_this.personalData.isPharmacist == 1) {
                  let url = _this.$router.resolve({
                    name: "drugPremangePatientList",
                  });
                  window.open(url.href, "_blank");
                } else {
                  let url = _this.$router.resolve({
                    name: "interrogationIndex",
                  });
                  window.open(url.href, "_blank");
                }
            });
          // _this.certificateAuditVisible = true
        } else {
          // 其他
        }
        }, function (error) {
            console.log(error);
        })
      // this.certificateAuditVisible = true
    },
    getHomeMessageData() {
        let _this = this;
        _this.Result.GetHomeMessage(function (data) {
            let readNumber = 0
            for (let i=0; i<data.data.length; i++) {
            readNumber = readNumber + data.data[i].unreadCount
            }
            if (readNumber > 0) {
            _this.$store.commit('messageData', true)
            } else {
            _this.$store.commit('messageData', false)
            }
        }, function (error) {
            console.log(error);
        })
    },
    gomessIndex() {
      this.$router.push({
        name: 'messageIndex',
        query: {
          typeCom: 0
        }
      })
    },
    getMenus() {
      var _this = this;
      _this.menuDomain.GetMenu(
        _this.roleId,
        function (data) {
          let keys = Object.keys(data.data)[0];
          _this.menuList = data.data[keys];
        },
        function (err) {
          console.log(err);
        }
      );
    },
    out() {
      loginOut();
      this.$message.success("操作成功");
    },
    goInterrogationIndex() {
      this.$router.push({
        name:'diseasePage'
      })
    },
    goInterrogationIndex1() {
      var _this = this
      if( _this.personalData.organizationId) {
          _this.goUploadUserCertificateAudit()
      }
      // _this.menuDomain.getVersion(
      //     function (data) {
      //       if (data.data.state == -1) {
      //            _this.$confirm('你当前为基础版，暂不支持门诊功能，请联系机构管理员或微信搜索"千越仁医"小程序升级更高版本解锁此功能', '提示', {
      //               confirmButtonText: '关闭',
      //               showCancelButton:false
      //             }).then(() => {
                    
      //             }).catch(() => {
                            
      //             });
			// 			} else {
      //         _this.goUploadUserCertificateAudit()
			// 			}
      //     },
      //     function (err) {
      //          _this.$confirm('你当前为基础版，暂不支持门诊功能，请联系机构管理员或微信搜索"千越仁医"小程序升级更高版本解锁此功能', '提示', {
      //               confirmButtonText: '关闭',
      //               showCancelButton:false
      //           }).then(() => {
                  
      //           }).catch(() => {
                          
      //           });
      //     }
      //   );
    },
    // goInterrogationIndex1(){
    //   this.certificateAuditVisible = true
    // },
    goInterShopIndex() {
      let url = this.$router.resolve({
        name: "shopPage",
      });
      window.open(url.href, "_blank");
    },
  },
  mounted() {
    this.$bus.$off("userhead");
    this.$bus.$on("userhead", msg => {
      if (msg) {
        this.userhead = msg;
      } else {
        this.userhead = "";
      }
    });
  },
};
</script>

<style scoped>
.navheader .topbar {
  background: #f6f6f6;
  height: 45px;
}

.navheader .topbar .topbar-box {
  width: 1200px;
  margin: 0 auto;
}

.navheader .topbar-l {
  float: left;
}

.navheader .topbar-l p {
  font-size: 12px;
  color: #333;
  line-height: 45px;
  height: 45px;
}

.navheader .topbar-l p span {
  color: #068679;
  font-size: 16px;
  padding: 0 10px;
}

.navheader .topbar-l p i {
  color: #222222;
  font-size: 14px;
  font-style: normal;
}

.navheader .personalCenter {
  /* margin: 0 50px; */
  position: relative;
  float: right;
  font-size: 14px;
  color: #333;
  line-height: 45px;
}

.navheader .personalCenter > div {
  display: inline-block;
  vertical-align: top;
}

.navheader .personalCenter .login-box > p {
  width: 150px;
  text-align: center;
  height: 45px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navheader .personalCenter .login-box > p .mini-head {
  display: inline-block;
  margin-right: 10px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
}

.navheader .personalCenter .login-box > p .mini-head img {
  width: 100%;
  height: 100%;
}

.navheader .personalCenter .login-box:hover .per-hover {
  display: block;
}

.navheader .feedback {
  width: 150px;
  font-size: 14px;
  color: #ffffff;
  background: #01c2ac;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
}

.navheader .test {
  width: 150px;
  font-size: 14px;
  color: #ffffff;
  background: orange;
  text-align: center;
  line-height: 45px;
  height: 45px;
  cursor: pointer;
}

.navheader .feedback a {
  color: #ffffff;
}

.navheader .feedback i {
  margin-right: 10px;
  line-height: 48px;
  height: 45px;
  /* font-weight: bold;/ */
}

.navheader .feedback span a {
  color: #ffffff;
}

.navheader .per-hover {
  display: none;
  position: absolute;
  left: -75px;
  width: 300px;
  height: 250px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 5px 0px 8px #d9d9d9;
  z-index: 9;
}

.navheader .head-box {
  padding-top: 15px;
  background: #f9f9f9;
  text-align: center;
}

.navheader .head-img {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.navheader .head-img img {
  width: 100%;
  height: 100%;
}

.navheader .per-info li {
  margin: 5px 10px;
  float: left;
  width: 76px;
  height: 68px;
}

.navheader .per-info li p {
  line-height: 1;
  text-align: center;
}

.navheader .per-info li:hover {
  background: #1fb7a6;
  border-radius: 5px;
}

.navheader .per-info li:hover p {
  color: #ffffff;
}

.navheader .s-icon {
  width: 25px;
  height: 25px;
  margin: 10px auto;
}

.navheader .s-icon1 {
  background: url("../assets/img/s_icon1.jpg") center no-repeat;
}

.navheader .s-icon2 {
  background: url("../assets/img/s_icon2.jpg") center no-repeat;
}

.navheader .s-icon3 {
  background: url("../assets/img/s_icon3.png") center no-repeat;
}
.navheader .s-icon4 {
  background: url("../assets/img/s_icon4.png") center no-repeat;
}
.navheader .per-info li:hover .s-icon1 {
  background: url("../assets/img/w_icon1.png") center no-repeat;
}

.navheader .per-info li:hover .s-icon2 {
  background: url("../assets/img/w_icon2.png") center no-repeat;
}

.navheader .per-info li:hover .s-icon3 {
  background: url("../assets/img/w_icon3.png") center no-repeat;
}
.navheader .per-info li:hover .s-icon4 {
  background: url("../assets/img/w_icon4.png") center no-repeat;
}
.navheader .status {
  border-top: 1px solid #ebebeb;
}

.navheader .status span {
  display: inline-block;
  font-size: 14px;
  color: #333;
  width: 49%;
  text-align: center;
  line-height: 60px;
}

/*-----------nav-----------*/
.navheader .nav-wrap {
  background: #ffffff;
  height: 80px;
}

.navheader nav {
  width: 1200px;
  margin: 0 auto;
}

.navheader nav .logo {
  float: left;
  width:auto;
  height: 50px;
  margin-top: 14px;
}
.navheader nav .logo img {
   width:auto;
  height: 50px;
  /* width: 100%;
  height: 100%; */
}

.navheader nav .navbar {
  float: right;
}

.navheader nav .navbar ul li {
  float: left;
  padding: 20px 30px;
  line-height: 40px;
  position: relative;
}

.navheader nav .navbar ul li:last-child {
  padding-right: 0px;
}

.navheader nav .navbar ul li a {
  display: inline-block;
  position: relative;
  border-width: 0;
  font-size: 16px;
  line-height: 40px;
}

.navheader nav .navbar ul li:hover > a {
  display: inline-block;
  color: #038678;
}

.navheader nav .navbar ul li > a::before {
  content: "";
  position: absolute;
  /* 重点 */
  left: 0;
  /* 重点 */
  bottom: 0;
  /* 重点 */
  width: 0;
  /* 重点 */
  height: 2px;
  background: #038678;
  transition: width 0.5s;
}

.navheader nav .navbar ul li:hover > a::before {
  width: 100%;
}

.navheader nav .navbar ul li.active > a::before {
  width: 100%;
}

.navheader nav .navbar ul li.active > a {
  color: #038678;
}

.navheader nav .navbar ul li:hover .sub-nav {
  display: block;
}

.navheader .sub-nav {
  display: none;
  position: absolute;
  top: 62px;
  padding: 10px 0;
  left: -20%;
  width: 150px;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 2px 0px 2px #f0f0f0;
  z-index: 9;
}

.navheader .sub-nav dt a {
  display: block !important;
  text-align: center;
  font-size: 14px !important;
  color: #333;
}

.navheader .sub-nav dt:hover a {
  color: #1fb7a6 !important;
}
.mesTopItem {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #EB1E1E;
  position: absolute;
  left: 12px;
  top: 12px;
}
</style>
<style>
.navheader .el-badge__content.is-dot {
  height: 6px;
  width: 6px;
}

.navheader .el-badge__content.is-fixed.is-dot {
  right: 0px;
  top: 11px;
}
.AuditVisibleBox{
  padding-left: 30px;
}
.AuditVisibleTitle {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin-top: 30px;
}
.AuditVisibleDes {
  font-size: 16px;
  color: #666666;
  margin: 15px 0;
  margin-top: 5px;
}
</style>
