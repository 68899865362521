import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import basePath from '../public/services.json'

import { fliters } from './utils/ruoyi'
import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts
var VueTouch = require('vue-touch')
Vue.use(VueTouch, {name: 'v-touch'})
Object.keys(fliters).forEach(key => {
	Vue.filter(key, fliters[key])
})
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './theme/element/index.css'
Vue.use(ElementUI)
// 签名
import vueEsign from 'vue-esign'
Vue.use(vueEsign)
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css' //引入样式
Vue.use(vueSwiper)
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

// import tinymce from 'tinymce'
// import VueTinymce from '@packy-tang/vue-tinymce'

// Vue.use(VueTinymce)
import axios from 'axios'
Vue.prototype.$axios = axios
// axios.defaults.baseURL = '/api' //关键代码
Vue.config.productionTip = false
Vue.prototype.$basePath = basePath

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
	/* 需要注册百度地图开发者来获取你的ak */
	ak: 'ngUWk0ZVKI23LdelY3T5lIbW6nU3VeU4',
})
import ServicesData from '../public/services.json';
Vue.prototype.$ServicesData1 = ServicesData

import VueLazyload from 'vue-lazyload'
// 配置项
Vue.use(VueLazyload, {
	loading: require('@/assets/img/loading.gif'),
	error: require('@/assets/img/zyc_img.png'),
	dispatchEvent: true,
	attempt: 1,
	preLoad: 1.3,
})

import qs from 'qs'
Vue.prototype.$qs = qs
//引入js-cookie
// import jsCookie from 'js-cookie'
// Vue.prototype.$cookie = jsCookie

import { getUserInfo } from './utils/userInfo'

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor /* { default global options } */)
import Moment from 'moment'
Vue.prototype.$moment = Moment //引用时间控件
//预加载数据到缓存
import { DiseaseUtility } from './utils/diseaseUtility'
import { ApiClient } from './components/tokenclient/ApiClient'

import { TokenClient } from './components/tokenclient/TokenClient'

initServer()

//禁止选择和复制
document.onselectstart = function () {
	return false
}
//禁止右键
document.oncontextmenu = function () {
	return false
}
Vue.prototype.openLoading = function () {
	const loading = this.$loading({
		// 声明一个loading对象
		lock: true, // 是否锁屏
		text: '正在加载...', // 加载动画的文字
		/*spinner: 'el-icon-loading',             // 引入的loading图标*/
		background: 'rgba(255, 255, 255, 0.8)', // 背景颜色
		body: true,
		customClass: 'mask', // 遮罩层新增类名
	})
	setTimeout(function () {
		// 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
		loading.close() // 关闭遮罩层
	}, 5000)
	return loading
}
//事件总线
var EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
	$bus: {
		get: function () {
			return EventBus
		},
	},
})
//
Vue.directive('title', {
	inserted: function (el, binding) {
		document.title = el.dataset.title
	},
})
// 导航守卫
//使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
	window.addEventListener("beforeunload", () => {
		localStorage.setItem("messageStore", JSON.stringify(store.state));
	  });
	  //在页面加载时读取localStorage里的状态信息
	  localStorage.getItem("messageStore") &&
		store.replaceState(
		  Object.assign(
			store.state,
			JSON.parse(localStorage.getItem("messageStore"))
		  )
		);
	if (
		to.path == '/login' ||
		to.path == '/thirdPartyLogin' ||
		to.path == '/company/disclaimer' ||
		to.path == '/company/UserAgreement' ||
		to.path == '/imgShow'
	) {
		next()
	} else {
		var user = getUserInfo()
		if (user == false) {
			next({
				path: '/login',
				query: { redirect: to.fullPath },
			})
			ElementUI.Message({
				message: '请先登录后才能继续浏览',
				type: 'warning',
			})
		} else {
			if (Vue.prototype.TokenClient.RefreshToken()) {
				next()
			} else {
				next({
					path: '/login',
					query: { redirect: to.fullPath },
				})
			}
		}
	}
})

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')

function initServer() {
	ApiClient.Get(
		'/services.json',
		false,
		[],
		null,
		function (res) {
			var data = JSON.parse(res)
			Vue.prototype.Services = {
				Authorization: data.Authorization,
				Disease: data.Disease,
				Drug: data.Drug,
				Statics: data.Statics,
				Referral: data.Referral,
				TCM: data.TCM,
				shoppingMall: data.shoppingMall,
				IDCard:data.IDCard,
				MsgWss:data.MsgWss,
				ClientId:data.ClientId
			}
			Vue.prototype.IdentityServer = data.IdentityServer
			Vue.prototype.ClientId = data.ClientId
			Vue.prototype.Secret = data.Secret

			Vue.prototype.TokenClient = new TokenClient(
				Vue.prototype.IdentityServer,
				Vue.prototype.ClientId,
				Vue.prototype.Secret,
                {
					GrantType: 'client_credentials',
                }
			)

			var diseaseUtilityDomain = new DiseaseUtility(
				Vue.prototype.TokenClient,
				Vue.prototype.Services
			)
		},
		function (ex) {
			console.log('加载配置文件错误！')
		}
	)
}
