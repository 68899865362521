import storage from '../../utils/customstorage'
import { Menus } from '../Menu/Menu'
import store from '../../store/index'
import  websocket from '../../utils/webSocket'
var tokenName = 'token'

let UserInfo = function (tokenClient, serviceAddr) {
	let TokenClient = tokenClient
	let ServiceBaseAddress = serviceAddr
	var menu = new Menus(TokenClient, ServiceBaseAddress)
	/**
	 * 用户登录
	 * @param {any} username
	 * @param {any} password
	 */
	this.Login = async function (longinInfo, success, error) {
		TokenClient.SetUser(longinInfo)
		let token = await TokenClient.Token()
		if (token) {
			storage.setItem({
				name: tokenName,
				value: token.AccessToken,
				expires: token.ExpiresIn * 1000,
			}) //将token存储起来
			// ws链接
			// getWebSocket()
			analysisToken(token.AccessToken)
			websocket.Init()
			this.Menu(store.state.localData.userInfo.roleId, success, error)
		} else {
			error()
		}
	}
	this.Menu = function (roleId, success, error) {
		var url =
			ServiceBaseAddress + '/api/User/Menus'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					store.commit("localData/menus", data);
					localStorage.setItem("messageStore", JSON.stringify(store.state));
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 *获取用户详情
	 * @param success
	 * @param error
	 * @constructor
	 */
	this.UserMes = function (success, error) {
		var url = ServiceBaseAddress + '/api/User/Info'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
					saveUserInfoInToken(jsonObj.data)
				}
			},
			error
		)
	}
	/**
	 * 修改密码
	 * @param success
	 * @param error
	 * @constructor
	 */
	this.ChangePassword = function (oldPassword, newPassword, success, error) {
		var url = ServiceBaseAddress + '/api/User/ChangePassword'
		var param = {
			oldPassword: oldPassword,
			newPassword: newPassword,
		}
		TokenClient.Put(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 修改用户信息
	 */
	this.Edit = function (
		userId,
		userName,
		phone,
		userTitleId,
		userPostId,
		roleId,
		organizationId,
		departmentId,
		telephone,
		address,
		abstract,
		photoFile,
		success,
		error
	) {
		var url = ServiceBaseAddress + '/api/User'
		var param = {
			id: parseInt(userId),
			userName: userName,
			phone: phone,
			userPostId: parseInt(userPostId),
			userTitleId: parseInt(userTitleId),
			roleId: parseInt(roleId),
			organizationId: parseInt(organizationId),
			departmentId: parseInt(departmentId),
			telephone: telephone,
			address: address,
			abstract: abstract,
			photoFile: photoFile,
		}
		TokenClient.Put(
			url,
			true,
			{ 'Content-Type': 'application/json' },
			param,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	this.RefeUser = function (organizationId, departmentId, success, error) {
		var url = ServiceBaseAddress + '/api/User/Key/Users/' + organizationId
		if (departmentId) {
			url += '/' + departmentId
		} else {
			url
		}
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	this.RefeAllUser = function (organizationId, departmentId, success, error) {
		var url = ServiceBaseAddress + '/api/User/Key/ZCUsers/' + organizationId
		if (departmentId) {
			url += '/' + departmentId
		} else {
			url
		}
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	this.UserInfo = function () {
		var info = store.state.localData.userinfo
		return info
	}

	this.IsShowDialog = function (success, error) {
		var url = ServiceBaseAddress + '/api/User/IsShowDialog'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.orgLogoDialog = function (orgId,type,key,keyWord,pageIndex,success, error) {
		var url = ServiceBaseAddress + '/api/OrganizationLogo/OrgLogoAll'
		if (orgId) {
            url += `/${orgId}`;
        }
        if (type) {
            url += `/${type}`;
        }
		if (key) {
            url += `/${key}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }else {
            url += `/%20`;
        }
        if(pageIndex) {
            url += `/${pageIndex}`;
        }
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	this.setDialog = function (success, error) {
		var url = ServiceBaseAddress + '/api/User/SetDialog'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function (data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
}
export { UserInfo }

//解析JWT
function analysisToken(token) {
	let strings = token.split('.') //截取token，获取载体
	var userinfo = JSON.parse(
		decodeURIComponent(
			escape(
				window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))
			)
		)
	) //解析，需要吧‘_’,'-'进行转换否则会无法解析
	if (userinfo.sub) {
		var greenChannel = 0
		if (
			(userinfo.hasOwnProperty('Param.GreenChannel') &&
				userinfo['Param.GreenChannel'] == 'GreenChannel') ||
			userinfo.Department == '绿色通道'
		) {
			greenChannel = 1
		}
		// storage.setItem({
		// 	name: 'userInfo',
		// 	value: {
		// 		id: parseInt(userinfo.sub ?? 0), //用户ID
		// 		name: userinfo.UserName, //用户姓名
		// 		phone: userinfo.Phone, //电话号
		// 		telephone: userinfo.telephone,
		// 		address: userinfo.address,
		// 		abstract: userinfo.abstract,
		// 		categoryKey: userinfo.CategoryKey,
		// 		categoryId: userinfo.CategoryId,
		// 		category: userinfo.Category,
		// 		sex: userinfo.Sex, //性别
		// 		photo: userinfo.PhotoFile, //头像
		// 		organizationId: parseInt(userinfo.OrganizationId ?? 0), //所属单位ID
		// 		organizationName: userinfo.OrganizationName, //所属单位名称
		// 		departmentId: parseInt(userinfo.DepartmentId ?? 0), //所属科室ID
		// 		department: userinfo.Department, //所属科室名称
		// 		title: userinfo.UserTitle, //职称
		// 		titleId: parseInt(userinfo.TitleId ?? 0), //职称ID
		// 		post: userinfo.UserPost, //职务
		// 		postId: parseInt(userinfo.PostId ?? 0), //职务ID
		// 		roleId: parseInt(userinfo.RoleId ?? 0),
		// 		isgreenChannel: greenChannel,
		// 	},
		// })
		store.commit("localData/userInfo", {
			id: parseInt(userinfo.sub ?? 0), //用户ID
			name: userinfo.UserName, //用户姓名
			phone: userinfo.Phone, //电话号
			telephone: userinfo.telephone,
			address: userinfo.address,
			abstract: userinfo.abstract,
			categoryKey: userinfo.CategoryKey,
			categoryId: userinfo.CategoryId,
			category: userinfo.Category,
			sex: userinfo.Sex, //性别
			photo: userinfo.PhotoFile, //头像
			organizationId: parseInt(userinfo.OrganizationId ?? 0), //所属单位ID
			organizationName: userinfo.OrganizationName, //所属单位名称
			departmentId: parseInt(userinfo.DepartmentId ?? 0), //所属科室ID
			department: userinfo.Department, //所属科室名称
			title: userinfo.UserTitle, //职称
			titleId: parseInt(userinfo.TitleId ?? 0), //职称ID
			post: userinfo.UserPost, //职务
			postId: parseInt(userinfo.PostId ?? 0), //职务ID
			roleId: parseInt(userinfo.RoleId ?? 0),
			isgreenChannel: greenChannel,
			isAuditDiagnose: userinfo.IsAuditDiagnose,
			isMakeDiagnose: userinfo.IsMakeDiagnose,
			detailUserType:userinfo.DetailUserType
		})
		localStorage.setItem("messageStore", JSON.stringify(store.state));
	}
	//将token存储起来
}

function saveUserInfoInToken(userinfo) {
	var oldInfo = store.state.localData.userinfo
	var greenChannel = 0
	if (
		(userinfo.hasOwnProperty('Param.GreenChannel') &&
			userinfo['Param.GreenChannel'] == 'GreenChannel') ||
		userinfo.Department == '绿色通道'
	) {
		greenChannel = 1
	}
	// storage.setItem({
	// 	name: 'userInfo',
	// 	value: {
	// 		id: userinfo.id, //用户ID
	// 		name: userinfo.userName, //用户姓名
	// 		phone: userinfo.phone, //电话号
	// 		telephone: userinfo.telephone,
	// 		address: userinfo.address,
	// 		abstract: userinfo.abstract,
	// 		categoryKey: oldInfo?.categoryKey,
	// 		categoryId: oldInfo?.categoryId,
	// 		category: oldInfo?.category,
	// 		sex: userinfo.sex, //性别
	// 		photo: userinfo.photoFile, //头像
	// 		organizationId: parseInt(userinfo.organizationId ?? 0), //所属单位ID
	// 		organizationName:
	// 			userinfo.organization == null ? '' : userinfo.organization.name, //所属单位名称
	// 		departmentId: parseInt(userinfo.departmentId ?? 0), //所属科室ID
	// 		department:
	// 			userinfo.department == null ? '' : userinfo.department.name, //所属科室名称
	// 		// title: userinfo.Title, //职称
	// 		titleId: parseInt(userinfo.userTitleId ?? 0), //职称ID
	// 		// post: userinfo.Post, //职务
	// 		postId: parseInt(userinfo.userPostId ?? 0), //职务ID
	// 		roleId: parseInt(userinfo.roleId ?? 0),
	// 		isgreenChannel: greenChannel,
	// 	},
	// })
	store.commit("localData/userInfo", {
		id: userinfo.id, //用户ID
		name: userinfo.userName, //用户姓名
		phone: userinfo.phone, //电话号
		telephone: userinfo.telephone,
		address: userinfo.address,
		abstract: userinfo.abstract,
		categoryKey: oldInfo?.categoryKey,
		categoryId: oldInfo?.categoryId,
		category: oldInfo?.category,
		sex: userinfo.sex, //性别
		photo: userinfo.photoFile, //头像
		organizationId: parseInt(userinfo.organizationId ?? 0), //所属单位ID
		organizationName:
			userinfo.organization == null ? '' : userinfo.organization.name, //所属单位名称
		departmentId: parseInt(userinfo.departmentId ?? 0), //所属科室ID
		department:
			userinfo.department == null ? '' : userinfo.department.name, //所属科室名称
		// title: userinfo.Title, //职称
		titleId: parseInt(userinfo.userTitleId ?? 0), //职称ID
		// post: userinfo.Post, //职务
		postId: parseInt(userinfo.userPostId ?? 0), //职务ID
		roleId: parseInt(userinfo.roleId ?? 0),
		isgreenChannel: greenChannel,
		isAuditDiagnose: userinfo.isAuditDiagnose ? 'True' : 'False',
		isMakeDiagnose: userinfo.isMakeDiagnose ? 'True' : 'False',
		detailUserType:userinfo.userType
	})
	localStorage.setItem("messageStore", JSON.stringify(store.state));
}
