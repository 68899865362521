<template>
  <div id="app" v-title :data-title="verSion">
    <router-view v-if="isRouterAlive && !$route.meta.keepAlive"></router-view>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      header_show: true,
      footer_show: true,
      isRouterAlive: true,
      verSion: "智能诊疗辅助系统",
      // verSion:'国医互联',
    };
  },
  components: {
    "pc-header": Header,
    "pc-footer": Footer,
  },
  mounted() {
    // let favicon = document.querySelector('link[rel="icon"]');
    // let link = `./favicon.ico`;
    // if (this.Services.ClientId == 'AssociationWeb') {
    //   link = `./mzlogo.ico`;
    // } else {
    //   link = `./favicon.ico`;
    // }
    // if (favicon !== null) {
    //   favicon.href = link;
    // } else {
    //   favicon = document.createElement("link");
    //   favicon.rel = "icon";
    //   favicon.href = link;
    //   document.head.appendChild(favicon);
    // }
    document.querySelectorAll("link[rel*='icon']").forEach((item) => {
      console.log(item);
      console.log(this.Services.ClientId);
      if (this.Services.ClientId == "AssociationWeb") {
        item.href = "/mzlogo.ico";
      } else {
        item.href = "/favicon.ico";
      }
    });
  },
  methods: {
    //是否显示头部
    header: function (bool) {
      this.header_show = bool;
    },
    //是否显示底部
    footer: function (bool) {
      this.footer_show = bool;
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
  created() {},
};
</script>
<style>
@import "./assets/css/common.css";

#app {
  width: 100%;
  height: 100%;
}
</style>
